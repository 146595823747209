import React, { Component } from "react";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import { BrowserView, MobileView } from "react-device-detect";

class PlayerApp extends Component {
  render() {
    return (
      <div>
        <BrowserView>
          <AudioPlayer
            src={this.props.audio}
            layout="horizontal"
            customAdditionalControls={[]}
            autoPlay={false}
            autoPlayAfterSrcChange={false}
          />
        </BrowserView>
        <MobileView>
          <AudioPlayer
            src={this.props.audio}
            layout="stacked"
            customAdditionalControls={[]}
            autoPlay={false}
            autoPlayAfterSrcChange={false}
          />
        </MobileView>
      </div>
    );
  }
}

export default PlayerApp;
