import React, { Component } from "react";
import Home from "./PageHome";
import Footer from "./footer";
import Header from "./header";
import Marquee from "./marquee";
import Newsletter from "./newsletter";
import PageEpisode from "./PageEpisode";
import PageAbout from "./PageAbout";
import PageSubmit from "./PageSubmit";
import PageContact from "./PageContact";
import PagePrivacy from "./PagePrivacy";
import PageTerms from "./PageTerms";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";

let Parser = require("rss-parser");
let parser = new Parser();

class App extends Component {
  constructor(props) {
    super(props);
    this.state = { rssFeed: [], episodeTite: "", swipeUpClass: "" };
  }
  currentEpisode = (title) => {
    this.setState({ episodeTitle: title, showArt: "" });
  };
  arrayCleanup(arr) {
    // clear out the trailer episode (no description, breaks the feed not needed for the site)
    // TODO: maybe add the trailer to the homepage header?
    for (let i = 0; i < arr.length; i++) {
      if (
        arr[i].link === "https://anchor.fm/justonelisten/episodes/ep-ess4h0"
      ) {
        // Remove Trailer
        arr.splice(i, 1);
      }
    }
    return arr;
  }
  sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  componentDidMount() {
    let ssData = sessionStorage.getItem("feedData");
    let ssShowArt = sessionStorage.getItem("showArt");
    let apiUrl =
      process.env.NODE_ENV === "production"
        ? "/api/feed.php"
        : "https://anchor.fm/s/4907dfc/podcast/rss";
    if (!ssData) {
      (async () => {
        let feed = await parser.parseURL(apiUrl);
        let arrEpisodes = feed.items; // <== we need to weed out the trailer look for "(Trailer)" in title
        let cleanArray = this.arrayCleanup(arrEpisodes);
        sessionStorage.setItem("feedData", JSON.stringify(cleanArray));
        sessionStorage.setItem("showArt", feed.image.url);

        this.setState({
          showArt: feed.image.url,
          rssFeed: arrEpisodes,
          swipeUpClass: "loading-anim-trans",
        });
      })();
    } else {
      //this.arrayCleanup(ssData);
      let cleanArray = this.arrayCleanup(JSON.parse(ssData));
      this.setState({
        rssFeed: cleanArray,
        showArt: ssShowArt,
      });
    }
  }
  render() {
    // This is a whacky way to deal with race condition but who cares?
    // TODO: Make a preloader
    if (!this.state.rssFeed.length > 0) {
      return (
        <div className={`loading ${this.state.swipeUpClass}`}>
          <img src="./jol-logo.png" alt="Just One Listen!" className="logo" />
          <img
            src="./loader.gif"
            alt="JMack's loading it up!"
            className="ani"
          />
        </div>
      );
    } else {
      return (
        <Router>
          <ScrollToTop />
          <div>
            <div id="__layout">
              <div>
                <Header />
                <div className="page">
                  <div className="page__content">
                    <div>
                      <Switch>
                        <Route exact path="/">
                          <Home rssFeed={this.state.rssFeed} />
                        </Route>
                        <Route
                          exact
                          path="/page/:pageNum?"
                          render={(props) => (
                            <Home rssFeed={this.state.rssFeed} {...props} />
                          )}
                        />
                        <Route
                          exact
                          path="/episode/:id"
                          render={(props) => (
                            <PageEpisode
                              rssFeed={this.state.rssFeed}
                              {...props}
                              setEpisode={this.currentEpisode}
                            />
                          )}
                        />
                        <Route exact path="/about">
                          <PageAbout />
                        </Route>
                        <Route exact path="/submit">
                          <PageSubmit />
                        </Route>
                        <Route exact path="/contact">
                          <PageContact />
                        </Route>
                        <Route exact path="/privacy">
                          <PagePrivacy />
                        </Route>
                        <Route exact path="/terms">
                          <PageTerms />
                        </Route>
                      </Switch>
                      <Newsletter />
                    </div>
                  </div>
                </div>
                <Footer />
                <Switch>
                  <Route exact path="/">
                    <Marquee
                      section="JUST ONE LISTEN"
                      content="New Episodes Every Day"
                      cssclass="highlight--scheme_01"
                    />
                  </Route>
                  <Route exact path="/page/:page?">
                    <Marquee
                      section="JUST ONE LISTEN"
                      content="New Episodes Every Day"
                      cssclass="highlight--scheme_01"
                    />
                  </Route>
                  <Route path="/episode/:id">
                    <Marquee
                      section="EPISODE"
                      content={this.state.episodeTitle}
                      cssclass="highlight--scheme_01"
                    />
                  </Route>
                  <Route exact path="/about">
                    <Marquee
                      section="ABOUT"
                      content="About The Show"
                      cssclass="highlight--scheme_06"
                    />
                  </Route>
                  <Route exact path="/submit">
                    <Marquee
                      section="SUBMIT YOUR SHOW"
                      content="Get On The Show"
                      cssclass="highlight--scheme_02"
                    />
                  </Route>
                  <Route exact path="/contact">
                    <Marquee
                      section="CONTACT"
                      content="Reach Out"
                      cssclass="highlight--scheme_11"
                    />
                  </Route>
                </Switch>
              </div>
            </div>
          </div>
        </Router>
      );
    }
  }
}

export default App;
