import React, { Component } from "react";

class PageAbout extends Component {
  render() {
    return (
      <iframe
        className="airtable-embed airtable-dynamic-height"
        src="https://airtable.com/embed/shrNRs7kow3f6lqA9?backgroundColor=yellow"
        frameBorder="0"
        title="Submit to Just One Listen"
        width="100%"
        height="1999"
      ></iframe>
    );
  }
}

export default PageAbout;
