import React, { Component } from "react";
import { Redirect } from "react-router-dom";

class Newsletter extends Component {
  constructor(props) {
    super(props);
    this.state = { randomLink: "" };
  }
  getEpisode = () => {
    let rssFeed = JSON.parse(sessionStorage.getItem("feedData"));

    let item = rssFeed[Math.floor(Math.random() * rssFeed.length)];
    this.setState({ randomLink: item.guid });
  };
  render() {
    const redirect = this.state.randomLink;
    if (redirect.length > 1) {
      this.setState({ randomLink: "" });
      return <Redirect to={`/episode/${redirect}`} />;
    } else {
      return (
        <section className="lucky section">
          <p className="lucky__headline">
            <span className="highlight-lucky-color--01">S</span>
            <span className="highlight-lucky-color--02">h</span>
            <span className="highlight-lucky-color--03">o</span>
            <span className="highlight-lucky-color--04">w</span>
            <span className="highlight-lucky-color--05"> </span>
            <span className="highlight-lucky-color--06">m</span>
            <span className="highlight-lucky-color--07">e</span>
            <span className="highlight-lucky-color--08"> </span>
            <span className="highlight-lucky-color--01">s</span>
            <span className="highlight-lucky-color--02">o</span>
            <span className="highlight-lucky-color--03">m</span>
            <span className="highlight-lucky-color--04">e</span>
            <span className="highlight-lucky-color--05">t</span>
            <span className="highlight-lucky-color--06">h</span>
            <span className="highlight-lucky-color--07">i</span>
            <span className="highlight-lucky-color--08">n</span>
            <span className="highlight-lucky-color--01">g</span>
            <span className="highlight-lucky-color--02"> </span>
            <span className="highlight-lucky-color--03">g</span>
            <span className="highlight-lucky-color--04">o</span>
            <span className="highlight-lucky-color--05">o</span>
            <span className="highlight-lucky-color--06">d</span>
            <span className="highlight-lucky-color--07">!</span>
          </p>
          <button onClick={this.getEpisode} className="button">
            Go!
          </button>
        </section>
      );
    }
  }
}

export default Newsletter;
