import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink,
} from "react-router-dom";

class PageContact extends Component {
  render() {
    return (
      <div className="playlist">
        <section className="section finder section--border">
          <div className="finder__container">
            <div className="finder__controls">
              <div>
                <img
                  src="jmack-about.png"
                  alt="About Me"
                  className="about-img"
                />
                <div className="about-text">
                  <p>
                    <strong>
                      Hi I'm JMack, and I've been podcasting for over 15 years.
                    </strong>
                  </p>
                  <p>
                    Just One Listen is a show I created to give back to the
                    podcasting community. I approach every podcast as an average
                    listener would but I come backed by knowledge and a boat
                    load of experience. I record each episode real time, offer
                    advice and pointers on everything from your artwork to your
                    description, audio treatment, production and speaking voice.
                    I then drop you a rating between 1 and 10, anything I give
                    over a score of 7 happily gets a 5-star review in Apple
                    Podcasts from yours truly.
                  </p>
                  <p>
                    I started back in 2005 with the HoodHype show which was a
                    hip-hop show my friend and I created to spread the word
                    about independent artists in a time before Spotify, iTunes,
                    Facebook or Tik Tok. Over the years we eventually grew to be
                    the biggest independent hip-hop podcast on the internet.
                    Celebrating celebrity interrviews, features in SPIN, XXL and
                    Vibe magazines, syndication with AOL Radio, Dash Radion,
                    SiriusXM Stars channel and partnering with Shade45. We also
                    were selected as iTunes featured shows five times and won an
                    award for Best Underground Hip-Hop Show at the VH1 Hip-Hop
                    Honors. We were one of the first to record a hip-hop podcast
                    live on location and scored thousands in ad deals with
                    sponsors, listener-backed supported events and promotions.
                    I've seen it all in the space.
                  </p>
                  <p>
                    Like most of you, my fellow podcasters, I'm a show host,
                    producer, engineer, writer, developer, designer, ad sales
                    rep and marketing lead. I just happened to have been doing
                    it for over 15 years.
                  </p>

                  <p>
                    <NavLink to={`/submit`}>
                      Do you want me to check out your show? Submit it here!
                    </NavLink>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default PageContact;
