import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink,
} from "react-router-dom";

class Footer extends Component {
  render() {
    return (
      <footer className="footer">
        <section className="signoff">
          <span>Reviews</span> <span>+</span> <span>Advice</span>
          <span>+</span> <span>Ideas</span>
        </section>
        <section className="socials">
          <NavLink
            to={`/`}
            className="nuxt-link-exact-active nuxt-link-active logo logo--header"
          >
            <img
              src="/jol-logo.png"
              alt="Just One Listen"
              className="footer-logo"
            />
            {/*}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="379"
              height="69"
              viewBox="0 0 379 69"
            >
              <path
                id="JUST_LISTEN"
                data-name="JUST    LISTEN"
                className="cls-1"
                d="M14.874,49.012c0,7.98-3.04,10.185-7.9,10.185a19.054,19.054,0,0,1-5.928-.912L-0.25,67.633a30.165,30.165,0,0,0,8.436,1.216c11.249,0,18.241-5.092,18.241-19.685V16.786H14.874V49.012ZM29.921,16.786V45.364c0,16.341,7.6,23.485,20.521,23.485,13.377,0,21.357-7.524,21.357-23.333V16.786H60.247v29.49c0,9.044-3.344,13.3-9.425,13.3-5.928,0-9.272-4.484-9.272-13.3V16.786H29.921ZM73.773,65.657c2.888,1.6,8.665,3.116,14.517,3.116,14.06,0,20.673-7.3,20.673-15.885,0-7.22-4.257-11.933-13.3-15.277-6.612-2.508-9.5-3.952-9.5-7.22,0-2.66,2.432-4.94,7.448-4.94a24.349,24.349,0,0,1,10.716,2.432l2.584-9.348A31.382,31.382,0,0,0,93.838,15.95c-12.085,0-19.381,6.688-19.381,15.429,0,7.448,5.548,12.161,14.061,15.125,6.156,2.2,8.588,4.028,8.588,7.22,0,3.344-2.812,5.548-8.132,5.548A29.555,29.555,0,0,1,76.129,56.08Zm46.816,2.356h11.628v-41.5H146.2V16.786h-39.37v9.729h13.757v41.5Zm62.773,0h32V58.285H194.991v-41.5H183.362V68.013Zm32.755-51.227V68.013h11.629V16.786H216.117Zm13.6,48.871c2.888,1.6,8.665,3.116,14.517,3.116,14.061,0,20.673-7.3,20.673-15.885,0-7.22-4.256-11.933-13.3-15.277-6.612-2.508-9.5-3.952-9.5-7.22,0-2.66,2.432-4.94,7.448-4.94a24.354,24.354,0,0,1,10.717,2.432l2.584-9.348a31.384,31.384,0,0,0-13.073-2.584c-12.084,0-19.381,6.688-19.381,15.429,0,7.448,5.548,12.161,14.061,15.125,6.156,2.2,8.588,4.028,8.588,7.22,0,3.344-2.812,5.548-8.132,5.548a29.557,29.557,0,0,1-12.845-3.192Zm46.816,2.356h11.629v-41.5H302.15V16.786H262.78v9.729h13.756v41.5ZM332.7,36.927H313.852V26.287h19.989v-9.5H302.223V68.013h32.682v-9.5H313.852V46.352H332.7V36.927Zm14.666,31.086V52.584c0-8.36-.152-15.581-0.456-22.345l0.228-.076a190.3,190.3,0,0,0,8.893,18.089l10.944,19.761h12.161V16.786H368.5V31.759a190.112,190.112,0,0,0,.988,21.357h-0.228a148.526,148.526,0,0,0-8.36-17.557L350.255,16.786H336.727V68.013h10.64Z"
              />
              <path
                id="ONE"
                className="cls-1"
                d="M153.218,55.729c0,7.987,6.06,13.164,14.168,13.164,7.705,0,13.766-4.7,13.766-12.722,0-7.906-5.378-13.284-14.248-13.284C159.439,42.886,153.218,47.421,153.218,55.729Zm4.856,0.16c0-4.214,4.174-6.542,9.031-6.542,5.257,0,9.19,2.368,9.19,6.5,0,4.094-3.732,6.582-9.03,6.582C162.008,62.432,158.074,60.024,158.074,55.89ZM180.71,36.466h-8.147c-4.415,0-8.228.08-11.8,0.241l-0.04-.12a100.341,100.341,0,0,0,9.552-4.7l10.435-5.779V19.69H153.66v5.619h7.906a100.4,100.4,0,0,0,11.278-.522v0.12a78.394,78.394,0,0,0-9.271,4.415l-9.913,5.619v7.144h27.05V36.466ZM164.3,1.511v9.953h-5.619V0.909H153.66v16.7h27.05V0.347h-5.017V11.465h-6.421V1.511H164.3Z"
              />
            </svg>
            {*/}
          </NavLink>
          <section className="social-links social-links--footer">
            <div className="social-links__row">
              <a
                href="https://facebook.com/justonelisten"
                target="_blank"
                rel="noreferrer"
                className="social-links__link pointer-hover pointer-hover--footer"
              >
                <span className="bitmap-icon bitmap-icon--facebook"></span>
              </a>
              <a
                href="https://www.tiktok.com/@justonelisten"
                target="_blank"
                rel="noreferrer"
                className="social-links__link pointer-hover pointer-hover--footer"
              >
                <span className="bitmap-icon bitmap-icon--tiktok"></span>
              </a>
              <a
                href="https://instagram.com/justonelisten"
                target="_blank"
                rel="noreferrer"
                className="social-links__link pointer-hover pointer-hover--footer"
              >
                <span className="bitmap-icon bitmap-icon--instagram"></span>
              </a>
              <a
                href="https://twitter.com/justonelisten"
                target="_blank"
                rel="noreferrer"
                className="social-links__link pointer-hover pointer-hover--footer"
              >
                <span className="bitmap-icon bitmap-icon--twitter"></span>
              </a>
            </div>

            {/* ADD FEED LINKS HERE 
            <div className="social-links__row">
              <a
                href="empty.html"
                target="_blank"
                rel="noreferrer"
                className="social-links__link pointer-hover pointer-hover--footer"
              >
                <span className="bitmap-icon bitmap-icon--apple-music"></span>
              </a>
              <a
                href="empty.html"
                target="_blank"
                rel="noreferrer"
                className="social-links__link pointer-hover pointer-hover--footer"
              >
                <span className="bitmap-icon bitmap-icon--deezer"></span>
              </a>
              <a
                href="empty.html"
                target="_blank"
                rel="noreferrer"
                className="social-links__link pointer-hover pointer-hover--footer"
              >
                <span className="bitmap-icon bitmap-icon--youtube"></span>
              </a>
            </div>
            */}
          </section>
        </section>
        <section className="legals">
          <div className="legals__copy">
            <p className="legals__copyright">
              © Just One Listen
              <br />
              <NavLink to="/privacy">Privacy</NavLink>|
              <NavLink to="/terms">Terms &amp; Conditions</NavLink>
            </p>
          </div>
        </section>
      </footer>
    );
  }
}

export default Footer;
