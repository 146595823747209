import React, { Component } from "react";
import { Helmet } from "react-helmet";
import PlayerApp from "./PlayerApp";
import parse from "html-react-parser";
import Episode from "./episode";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink,
} from "react-router-dom";

class PageEpisode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      episodeTitle: "",
      coverArt: "",
      description: "",
      episodeMp3: "",
      episodeData: [],
      randomEpisodeNum: 0,
      guid: "",
    };
  }
  toggleModal = () => {
    if (document.body.classList[0] === "modal-open") {
      document.body.classList.remove("modal-open");
    } else {
      document.body.classList.add("modal-open");
    }
  };
  urlify(text) {
    let urlRegex = /(https?:\/\/[^\s]+)/g;
    // clean up and remove html tags
    let cleanedText = text.replace(/(<([^>]+)>)/gi, "");
    cleanedText = cleanedText.replace("Show Link: ", "");
    // create link and return it
    return cleanedText.replace(urlRegex, function (url) {
      return '<a href="' + url + '" target="_blank">Show Link</a></p>';
    });
  }

  removeShowLink(text) {
    let urlRegex = /Show Link: (https?:\/\/[^\s]+)/g;
    return text.replace(urlRegex, function (url) {
      return "";
    });
  }
  componentDidUpdate() {
    let newGuid = this.props.match.params.id;
    let currentGuid = this.state.guid;

    if (newGuid !== currentGuid) {
      this.processData();
    }
  }
  componentDidMount() {
    this.processData();
  }

  processData = () => {
    let guid = this.props.match.params.id;
    let rssFeed = JSON.parse(sessionStorage.getItem("feedData"));
    this.setState({ guid: guid });
    let episodeItem = rssFeed.filter(function (item) {
      return item.guid === guid;
    });

    /*
    Episodes to state for the 5 random episodes below
    
    Generate random starting number
    */
    let dataLength = rssFeed.length - 5;
    let rndEpNum = Math.ceil(Math.random() * dataLength);

    this.setState({ episodeData: rssFeed, randomEpisodeNum: rndEpNum });

    if (episodeItem.length > 0) {
      let rawTitle = episodeItem[0].title;
      let epTitle = rawTitle.replace("Podcast Review: ", "");
      // bubble up title for the marquee!
      // TODO: maybe pull marquee into each individual page that way no bubbling and weird marquee router?
      this.props.setEpisode(epTitle);

      this.setState({
        episodeTitle: epTitle,
        coverArt: episodeItem[0].itunes.image,
        description: episodeItem[0].content,
        episodeMp3: episodeItem[0].enclosure.url,
      });
    }
  };
  render() {
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Just One Listen - {this.state.episodeTitle}</title>
          <meta
            name="title"
            content={`Podcast Review: ${this.state.episodeTitle}`}
          />
          <meta
            name="description"
            content={parse(this.removeShowLink(this.state.description))}
          />

          <meta property="og:type" content="website" />
          <meta
            property="og:url"
            content={`https://justonelisten.com/episode/${this.state.guid}`}
          />
          <meta
            property="og:title"
            content={`Podcast Review: ${this.state.episodeTitle}`}
          />
          <meta
            property="og:description"
            content={parse(this.removeShowLink(this.state.description))}
          />
          <meta property="og:image" content={this.state.coverArt} />
          <meta property="og:locale" content="en_US" />
          <meta property="twitter:card" content="summary_large_image" />
          <meta
            property="twitter:url"
            content={`https://justonelisten.com/episode/${this.state.guid}`}
          />
          <meta
            property="twitter:title"
            content={`Podcast Review: ${this.state.episodeTitle}`}
          />
          <meta
            property="twitter:description"
            content={parse(this.removeShowLink(this.state.description))}
          />
          <meta property="twitter:image" content={this.state.coverArt} />

          <link
            rel="canonical"
            href={`https://justonelisten.com/episode/${this.state.guid}`}
          />
        </Helmet>
        <div className="episode-container">
          <div className="container">
            <section className="section playlist__hero">
              <div className="playlist__cover">
                <div className="cover">
                  <img
                    src={this.state.coverArt}
                    className="cover__artwork"
                    alt="Cover Art"
                  />
                </div>
              </div>
              <div className="playlist__about">
                <h1>{this.state.episodeTitle}</h1>
                <div className="playlist__description">
                  {parse(this.urlify(this.state.description))}
                </div>
                <section className="services">
                  <div className="services__title">
                    <div className="bitmap-icon--rotate-180deg bitmap-icon bitmap-icon--hand-left finder__pointer"></div>
                    <h2>Subscribe to the Show</h2>
                    <div className="bitmap-icon--rotate-180deg bitmap-icon bitmap-icon--hand-left finder__pointer"></div>
                  </div>
                  <div className="services__list">
                    <div className="services__item">
                      <a
                        href="https://podcasts.apple.com/us/podcast/just-one-listen-podcast-reviews/id1555172135?uo=4"
                        target="_blank"
                        rel="noreferrer"
                        className="save wmg-button button button--square button--apple"
                      >
                        <div className="services__button-content">
                          <span className="icon icon--apple"></span>{" "}
                          <span>Apple</span>
                        </div>
                      </a>

                      <div className="services__view">
                        <span>or&nbsp;</span>
                        <a
                          href="https://podcasts.apple.com/us/podcast/just-one-listen-podcast-reviews/id1555172135?uo=4"
                          className="services__link"
                          target="_blank"
                          rel="noreferrer"
                        >
                          View on Apple
                        </a>
                      </div>
                    </div>
                    <div className="services__item">
                      <a
                        href="https://www.google.com/podcasts?feed=aHR0cHM6Ly9hbmNob3IuZm0vcy80OTA3ZGZjL3BvZGNhc3QvcnNz"
                        target="_blank"
                        rel="noreferrer"
                        className="save wmg-button button button--square button--google"
                      >
                        <div className="services__button-content">
                          <span className="icon icon--googlepodcast"></span>{" "}
                          <span>Google</span>
                        </div>
                      </a>

                      <div className="services__view">
                        <span>or&nbsp;</span>
                        <a
                          href="https://www.google.com/podcasts?feed=aHR0cHM6Ly9hbmNob3IuZm0vcy80OTA3ZGZjL3BvZGNhc3QvcnNz"
                          target="_blank"
                          rel="noreferrer"
                          className="services__link"
                        >
                          View on Google
                        </a>
                      </div>
                    </div>
                    <div className="services__item">
                      <a
                        href="https://open.spotify.com/show/6hIIBcVOtV0D3bANqMndBz"
                        target="_blank"
                        rel="noreferrer"
                        className="save wmg-button button button--square button--spotify"
                      >
                        <div className="services__button-content">
                          <span className="icon icon--spotify"></span>{" "}
                          <span>Spotify</span>
                        </div>
                      </a>

                      <div className="services__view">
                        <span>or&nbsp;</span>
                        <a
                          href="https://open.spotify.com/show/6hIIBcVOtV0D3bANqMndBz"
                          className="services__link"
                        >
                          View on Spotify
                        </a>
                      </div>
                    </div>
                    <div className="services__item">
                      <a
                        href="https://www.stitcher.com/show/just-one-listen-podcast-reviews"
                        target="_blank"
                        rel="noreferrer"
                        className="save button button--square button--stitcher"
                      >
                        <div className="services__button-content">
                          <span className="icon icon--stitcher"></span>{" "}
                          <span>Stitcher</span>
                        </div>
                      </a>
                    </div>
                  </div>
                </section>
              </div>
              <section className="modal" onClick={this.toggleModal}>
                <div id="share" className="share">
                  <div className="modal__close-overlay"></div>
                  <div className="container">
                    <div className="modal__content">
                      <a
                        href={`https://www.facebook.com/sharer/sharer.php?u=http://justonelisten/episode/${this.props.id}`}
                        target="_blank"
                        rel="noreferrer"
                        className="share__item pointer-hover pointer-hover--left"
                      >
                        <span className="bitmap-icon bitmap-icon--facebook"></span>
                        <span>Facebook</span>
                      </a>
                      <a
                        href={`https://twitter.com/intent/tweet?text=http://justonelisten/episode/${this.props.id}`}
                        target="_blank"
                        rel="noreferrer"
                        className="share__item pointer-hover pointer-hover--left"
                      >
                        <span className="bitmap-icon bitmap-icon--twitter"></span>
                        <span>Twitter</span>
                      </a>
                      <a
                        href={`https://www.facebook.com/sharer/sharer.php?u=http://justonelisten/episode/${this.props.id}`}
                        target="_blank"
                        rel="noreferrer"
                        className="share__item pointer-hover pointer-hover--left"
                      >
                        <span className="bitmap-icon bitmap-icon--messenger"></span>
                        <span>Messenger</span>
                      </a>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        className="share__item pointer-hover pointer-hover--left share__item--link"
                      >
                        <span className="bitmap-icon bitmap-icon--link"></span>
                        <span>Copy link</span>
                        <div className="share__message">Copied</div>
                      </a>
                    </div>
                  </div>
                </div>
              </section>
              <button onClick={this.toggleModal} className="playlist__share">
                <span>Share Episode</span>
                <span className="icon icon--share"></span>
              </button>
            </section>
          </div>
          <div className="container container--narrow">
            <section className="section section--border">
              <section className="embed">
                <PlayerApp audio={this.state.episodeMp3} />
              </section>
            </section>
            <div className="section">
              <h2>Other Episodes</h2>
            </div>
            <section className="list list--narrow">
              {this.state.episodeData
                .slice(
                  this.state.randomEpisodeNum,
                  this.state.randomEpisodeNum + 4
                )
                .map((item) => (
                  <Episode
                    key={item.guid}
                    image={item.itunes.image}
                    title={item.title}
                    episode={item.itunes.episode}
                    guid={item.guid}
                  />
                ))}
            </section>

            <section className="related section">
              <h3>Find more episodes by category:</h3>
              <div className="related__list">
                <div className="related__item">
                  <NavLink to="/" className="button">
                    Reviews
                  </NavLink>
                </div>
                <div className="related__item">
                  <NavLink to="/" className="button">
                    The Right Way
                  </NavLink>
                </div>
                <div className="related__item">
                  <NavLink to="/" className="button">
                    Tips
                  </NavLink>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    );
  }
}

export default PageEpisode;
