import React, { Component } from "react";
import Episode from "./episode";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink,
} from "react-router-dom";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      episodeData: [],
      showArt: "",
      page: 1,
      itemStart: 0,
      itemsPerPage: 20,
      episodeItems: [],
      maxPages: 0,
      modalStatus: "modal-closed",
    };
  }
  componentDidMount = () => {
    let data = sessionStorage.getItem("feedData");
    let itemData = JSON.parse(data);
    let artwork = sessionStorage.getItem("showArt");
    let pageNum = this.props.match;
    let setPage = parseInt(pageNum === undefined ? 1 : pageNum.params);
    let itemStart = this.state.itemStart;
    let itemEnd = itemStart + this.state.itemsPerPage;
    let maxPages = Math.ceil(itemData.length / this.state.itemsPerPage);

    if (setPage > 1) {
      itemEnd = this.state.itemsPerPage * setPage;
      itemStart = itemEnd - this.state.itemsPerPage + 1;
    }

    this.setState({
      page: setPage,
      episodeData: itemData,
      showArt: artwork,
      episodeItems: itemData.slice(itemStart, itemEnd),
      maxPages: maxPages,
    });
  };
  toggleModal = () => {
    this.setState({
      modalStatus:
        this.state.modalStatus === "modal-closed" ? "" : "modal-closed",
    });
    this.scrollToTop();
  };
  scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
  componentDidUpdate() {
    let pageNum = this.props.match;
    let prevPage = this.state.page;
    let setPage = parseInt(pageNum === undefined ? 1 : pageNum.params.pageNum);

    if (prevPage !== setPage) {
      let itemStart = this.state.itemStart;
      let itemEnd = itemStart + this.state.itemsPerPage;
      let allEpisodeItems = this.state.episodeData;

      if (setPage > 1) {
        itemEnd = this.state.itemsPerPage * setPage;
        itemStart = itemEnd - this.state.itemsPerPage + 1;
      }
      this.setState({
        page: setPage,
        episodeItems: allEpisodeItems.slice(itemStart, itemEnd),
      });
    }
  }
  render() {
    return (
      <section className="playlist">
        {/*}
        <Helmet>
          <meta charSet="utf-8" />
          <title>Just One Listen Podcast Reviews</title>
          <meta name="title" content="Just One Listen Podcast Reviews" />
          <meta
            name="description"
            content="One Podcast. One Review. One Episode Every Day."
          />

          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://justonelisten.com" />
          <meta property="og:title" content="Just One Listen Podcast Reviews" />
          <meta
            property="og:description"
            content="One Podcast. One Review. One Episode Every Day."
          />
          <meta
            property="og:image"
            content="https://justonelisten.com/fb_og.jpg"
          />
          <meta property="og:locale" content="en_US" />
          <meta property="twitter:card" content="summary_large_image" />
          <meta property="twitter:url" content="https://justonelisten.com" />
          <meta
            property="twitter:title"
            content="Just One Listen Podcast Reviews"
          />
          <meta
            property="twitter:description"
            content="One Podcast. One Review. One Episode Every Day."
          />
          <meta
            property="twitter:image"
            content="https://justonelisten.com/tw-og-header.jpg"
          />

          <link rel="canonical" href="https://justonelisten.com" />
        </Helmet>
        {*/}
        <section className="section finder section--border">
          <div className="finder__container">
            <div className="finder__controls">
              <div>
                <div className="bitmap-icon--rotate-90deg bitmap-icon bitmap-icon--hand-right finder__pointer"></div>

                <div className="show-art-container">
                  <img
                    src={this.state.showArt}
                    alt="Just One Listen"
                    className="show-art"
                  />
                </div>
                <div className="header-text">
                  <h1>Let's give it "Just One Listen"</h1>
                  <p>
                    Every day, I pick one podcast and give it "Just one Listen"
                    and give it a rating between 1 and 10.
                  </p>
                  <p>
                    Want your show reviewed?{" "}
                    <NavLink to={`/submit`}>Submit it here.</NavLink>
                  </p>
                  <p className="subscribe--btns">
                    <a
                      href="https://podcasts.apple.com/us/podcast/just-one-listen-podcast-reviews/id1555172135?uo=4"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src="/btn_applepodcast.png"
                        alt="Subscribe On Apple Podcasts"
                      />
                    </a>
                    <a
                      href="https://open.spotify.com/show/6hIIBcVOtV0D3bANqMndBz"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src="/btn_spotify.png" alt="Subscribe On Spotify" />
                    </a>
                    <a
                      href="https://www.google.com/podcasts?feed=aHR0cHM6Ly9hbmNob3IuZm0vcy80OTA3ZGZjL3BvZGNhc3QvcnNz"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src="/btn_googlepodcast.png"
                        alt="Subscribe On Google Podcasts"
                      />
                    </a>

                    <span className="modal-more" onClick={this.toggleModal}>
                      more ...
                    </span>

                    <div
                      onClick={this.toggleModal}
                      className={`modal-bg ${this.state.modalStatus}`}
                    ></div>
                    <div className={`modal-sub  ${this.state.modalStatus}`}>
                      <button onClick={this.toggleModal}>X</button>
                      <p>Subscribe to the show on any of these services!</p>
                      <a
                        href="https://music.amazon.com/podcasts/f03d84d6-79bd-489f-b7c6-d0dbf0ec7d6b/Just-One-Listen-Podcast-Reviews"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src="/btn_amazonmusic.png"
                          alt="Subscribe On Amazon Music &amp; Audible"
                        />
                      </a>
                      <a
                        href="https://iheart.com/podcast/82588348/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src="/btn_iheartradio.png"
                          alt="Subscribe On iHeartRadio"
                        />
                      </a>
                      <a
                        href="https://castbox.fm/channel/Just-One-Listen-Podcast-Reviews-id3940609?country=us"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src="/btn_castbox.png"
                          alt="Subscribe On CastBox"
                        />
                      </a>
                      <a
                        href="https://justonelisten.com/feed"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src="/btn_rss.png"
                          alt="Subscribe On Google Podcasts"
                        />
                      </a>
                      <a
                        href="https://overcast.fm/itunes1555172135"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src="/btn_overcast.png"
                          alt="Subscribe On OverCast"
                        />
                      </a>
                      <a
                        href="https://pca.st/bsrlej4t"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src="/btn_pocketcasts.png"
                          alt="Subscribe On PocketCasts"
                        />
                      </a>
                      <a
                        href="https://podcastaddict.com/podcast/3339427"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src="/btn_podcastaddict.png"
                          alt="Subscribe On Podcast Addict"
                        />
                      </a>
                      <a
                        href="https://www.stitcher.com/show/just-one-listen-podcast-reviews"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src="/btn_stitcher.png"
                          alt="Subscribe On Stitcher"
                        />
                      </a>
                    </div>
                  </p>
                </div>
                <div className="bitmap-icon--rotate-270deg bitmap-icon bitmap-icon--hand-left finder__pointer"></div>
              </div>
            </div>{" "}
          </div>
        </section>
        <section className="description section section--border description--left">
          <p>Listen to all of my episodes below!</p>
        </section>
        <section className="list list--">
          {this.state.episodeItems.map((item) => (
            <Episode
              key={item.guid}
              image={item.itunes.image}
              title={item.title}
              episode={item.itunes.episode}
              guid={item.guid}
            />
          ))}
        </section>
        <section className="description section  description--center">
          <p>
            {this.state.page > 1 && (
              <NavLink
                to={`/page/${this.state.page - 1}`}
                className="prev-episodes"
              >
                <img src="/lefthand.png" alt="Previous Page" /> Prev Episodes
              </NavLink>
            )}
            {this.state.page < this.state.maxPages && (
              <NavLink
                to={`/page/${this.state.page + 1}`}
                className="next-episodes"
              >
                More Episodes <img src="/righthand.png" alt="Next Page" />
              </NavLink>
            )}
          </p>
        </section>
      </section>
    );
  }
}

export default Home;
