import React, { Component } from "react";
import "./Marquee.css";

class Marquee extends Component {
  render() {
    return (
      <aside
        text="justonelisten.com"
        className={`sidebar ${this.props.cssclass}`}
      >
        <div className="sidebar__marquee">
          <div className="sidebar__marquee__inner">
            <span>
              <h1>
                {this.props.section} • {this.props.content}
              </h1>
              •&nbsp;
            </span>
            <span>
              {this.props.section} • {this.props.content} •&nbsp;
            </span>
            <span>
              {this.props.section} • {this.props.content} •&nbsp;
            </span>{" "}
            <span>
              {this.props.section} • {this.props.content} •&nbsp;
            </span>{" "}
            <span>
              {this.props.section} • {this.props.content} •&nbsp;
            </span>{" "}
            <span>
              {this.props.section} • {this.props.content} •&nbsp;
            </span>{" "}
            <span>
              {this.props.section} • {this.props.content} •&nbsp;
            </span>{" "}
            <span>
              {this.props.section} • {this.props.content} •&nbsp;
            </span>{" "}
            <span>
              {this.props.section} • {this.props.content} •&nbsp;
            </span>{" "}
            <span>
              {this.props.section} • {this.props.content} •&nbsp;
            </span>{" "}
            <span>
              {this.props.section} • {this.props.content} •&nbsp;
            </span>{" "}
            <span>
              {this.props.section} • {this.props.content} •&nbsp;
            </span>{" "}
            <span>
              {this.props.section} • {this.props.content} •&nbsp;
            </span>
            <span>
              {this.props.section} • {this.props.content} •&nbsp;
            </span>
            <span>
              {this.props.section} • {this.props.content} •&nbsp;
            </span>
            <span>
              {this.props.section} • {this.props.content} •&nbsp;
            </span>
          </div>
        </div>
      </aside>
    );
  }
}

export default Marquee;
