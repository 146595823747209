import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink,
} from "react-router-dom";

class PageAbout extends Component {
  render() {
    return (
      <div className="playlist">
        <section className="section finder section--border">
          <div className="finder__container">
            <img src="./jmack.png" className="contact-img" />
            <div className="finder__controls">
              <div>
                <div className="contact-text">
                  <p>
                    Want to submit your show?{" "}
                    <NavLink to="/submit">
                      Please fill out the submission page!
                    </NavLink>
                  </p>
                  <p>Otherwise, got questions or ideas?</p>
                  <p className="section--border">
                    <strong>
                      email me:{" "}
                      <a href="mailto:jmack@justonelisten.com">
                        jmack@justonelisten.com
                      </a>
                    </strong>
                  </p>
                  <p>
                    I run a Patreon if you'd like to help support the show!
                    <br />
                    <a
                      href="https://www.patreon.com/bePatron?u=54277707"
                      className="patreon"
                      rel="noreferrer"
                      target="_blank"
                    >
                      <img
                        src="./become-patron.png"
                        alt="Become a Patreon Supporter!"
                      />
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default PageAbout;
