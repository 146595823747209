import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink,
} from "react-router-dom";

// Handle root nav item so it doesnt trigger for ALL calls
const checkActive = (match, location) => {
  if (!location) return false;
  const { pathname } = location;

  return pathname === "/" || pathname.substring(0, 8) === "/episode";
};

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileMenu: "",
      mobileMenuButton: "",
    };
  }
  openMobile = () => {
    let mobileMenu = this.state.mobileMenu;

    this.setState({
      mobileMenu: mobileMenu === "is-open" ? "" : "is-open",
      mobileMenuButton: mobileMenu === "is-open" ? "" : "is-open",
    });
  };
  render() {
    return (
      <header className="header">
        <NavLink
          to={`/`}
          href=""
          className="nuxt-link-exact-active nuxt-link-active logo logo--header"
        >
          <img
            src="/jol-darkbg.png"
            alt="Just One Listen"
            className="header-logo"
          />
        </NavLink>
        <nav className="navigation">
          <NavLink
            to={`/`}
            className="navigation__link navigation__link--playlists highlight-inactive--scheme_01"
            aria-current="page"
            activeClassName="active"
            isActive={checkActive}
          >
            Episodes
          </NavLink>

          <NavLink
            to={`/about`}
            className="navigation__link navigation__link--playlists highlight-inactive--scheme_06"
            activeClassName="active"
            aria-current="page"
          >
            About
          </NavLink>

          <NavLink
            to={`/submit`}
            className="navigation__link navigation__link--playlists highlight-inactive--scheme_02"
            activeClassName="active"
            aria-current="page"
          >
            Submit Your Show
          </NavLink>
          <NavLink
            to={`/contact`}
            className="navigation__link navigation__link--playlists highlight-inactive--scheme_11"
            activeClassName="active"
            aria-current="page"
          >
            Contact
          </NavLink>
        </nav>
        <button
          id="burger"
          className={`open-main-nav ${this.state.mobileMenuButton}`}
          onClick={this.openMobile}
        >
          <span className="burger"></span>
        </button>
        <nav className={`main-nav  ${this.state.mobileMenu}`} id="main-nav">
          <ul>
            <li>
              <NavLink
                to={`/`}
                isActive={checkActive}
                onClick={this.openMobile}
              >
                Episodes
              </NavLink>
            </li>
            <li>
              <NavLink
                to={`/about`}
                aria-current="page"
                onClick={this.openMobile}
              >
                About
              </NavLink>
            </li>
            <li>
              <NavLink
                to={`/submit`}
                aria-current="page"
                onClick={this.openMobile}
              >
                Submit Your Show
              </NavLink>
            </li>
            <li>
              <NavLink
                to={`/contact`}
                aria-current="page"
                onClick={this.openMobile}
              >
                Contact
              </NavLink>
            </li>
          </ul>
        </nav>
      </header>
    );
  }
}

export default Header;
