import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
function padToTwo(number) {
  if (number <= 9) {
    number = ("0" + number).slice(-2);
  }
  let strNum = number === "00" ? "05" : number;
  return strNum;
}

function bigwords(title) {
  let arrTitle = title.split(" ");
  for (let i = 0; i < arrTitle.length; i++) {
    if (arrTitle[i].length > 8) {
      return true;
    }
  }
}
class Episode extends Component {
  constructor(props) {
    super(props);
    this.state = { schemeNumber: "00", episodeSizeClass: "episode_txt_short" };
  }
  componentDidMount() {
    let episodeNumber = this.props.episode;
    let lastNum = 2;
    if (parseInt(episodeNumber) > 0) {
      lastNum = episodeNumber.toString().split("").pop();
    }

    // Set Text Length (we subtract 17 for the "podcast review:" prefix)

    let titleLengthThreshold = 17;
    if (this.props.title.length - 17 > titleLengthThreshold) {
      this.setState({ episodeSizeClass: "episode_txt_long" });
    } else if (
      bigwords(this.props.title) &&
      this.props.title.length - 17 < titleLengthThreshold
    ) {
      this.setState({ episodeSizeClass: "episode_txt_med" });
    } else if (
      bigwords(this.props.title) &&
      this.props.title.length - 17 < titleLengthThreshold
    ) {
      this.setState({ episodeSizeClass: "episode_txt_short" });
    }

    let schemeNum = padToTwo(lastNum);
    this.setState({ schemeNumber: schemeNum });
  }
  render() {
    //let cleanTitle = this.props.title.replace("Podcast Review: ", "");
    //let arrWords = cleanTitle.split(" ");
    return (
      <div className="list__item">
        <div className={`cover highlight--scheme_${this.state.schemeNumber}`}>
          <div className="list__titleoverlay">
            <span className={`episode_title ${this.state.episodeSizeClass}`}>
              {this.props.title.replace("Podcast Review: ", "")}
            </span>
          </div>
          <img
            src={this.props.image}
            className="cover__artwork"
            alt={this.props.title}
          />
        </div>
        <Link to={`/episode/${this.props.guid}`} className="episode_link">
          <div className="list__overlay">
            <div className="button button--large">View Episode</div>
          </div>
        </Link>
      </div>
    );
  }
}

export default Episode;
